import { Layout, PostLayout, PostPostLayout } from "@components/Layout"
import { RelatedArticles, VideoBlock } from "@components/Post"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Hero } from "@components/Hero"
import { SeoPost } from "@components/Seo"
import { Share } from "@components/Share"
import { Arrow } from "@components/UI"
import { usePageSetup } from "@components/usePageSetup"
import { splitText } from "@utils"
import React from "react"

const PostTemplate = ({ data }) => {
  const post = data.allWpPost.edges[0].node
  const { seo, title, slug, date, excerpt, categories, article, featuredImage } = post

  // const featuredImageUrl = featuredImage?.node?.localFile?.publicURL || "/images/meta/jk.jpg"

  const { themeColor } = usePageSetup({
    themeColor: article.theme,
    navigationColor: "text-white",
    displayGeneralForm: false,
  })
  const layouts = article.articleComponents || []
  const postLayouts = article.articlePostComponents || []

  return (
    <Layout>
      <SeoPost seo={seo} />
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26" containerClass="container flex flex-col">
          <div className="tracking-normal text-white font-semi text-14">
            <Link to={`/ideas-and-insights`} className="tracking-normal text-white font-semi text-14 hover:underline">
              Ideas and insights
            </Link>
            <span> &gt; </span>
            <span className="tracking-normal text-white font-semi text-14">{title}</span>
          </div>
          <Hero.Title>
            <div className="grid grid-col-10 mt-clamp-10-14">
              <div className="col-span-10">
                <div className="leading-6 tracking-normal text-white font-semi text-14" style={{ maxWidth: "25rem" }}>
                  {categories.nodes.map((category, index) => {
                    return (
                      <Link
                        key={index}
                        to={`/category/${category.slug}`}
                        className="tracking-normal text-white font-semi text-14 hover:underline"
                      >
                        {index !== 0 ? `, ${category.name}` : category.name}
                      </Link>
                    )
                  })}
                </div>
                <div className="mt-clamp-5-8">
                  <h1
                    data-aos="stagger"
                    className="text-headline"
                    dangerouslySetInnerHTML={{
                      __html: splitText({
                        "text-white": title,
                      }),
                    }}
                  />
                </div>
              </div>
            </div>
          </Hero.Title>
          <div className="mx-auto mt-12 lg:w-10/12">
            <div className="flex flex-wrap gap-8" data-aos="fade-left" data-aos-delay="600">
              <div className="flex items-center text-white">
                {article?.author?.[0] && (
                  <h4 className="text-12">{`Our thought leader${article?.author.length > 1 ? "s" : ""}:`}</h4>
                )}
              </div>
              {article?.author?.[0]
                ? article?.author.map((author) => {
                    return (
                      <div
                        key={author?.title}
                        className="inline-flex text-white border rounded-full"
                        style={{ minWidth: "200px" }}
                      >
                        <div
                          className="overflow-hidden rounded-full bg-teal"
                          style={{ minWidth: "55px", width: "55px", height: "55px" }}
                        >
                          <GatsbyImage
                            image={getImage(author?.profile?.profile?.localFile)}
                            alt={author?.title}
                            className="object-cover w-full h-full"
                          />
                        </div>
                        <div className="grid items-center pr-8 ml-4 text-12">
                          <span>
                            <span className="font-bold">{author?.title}</span>
                            <br />
                            {author?.profile?.title}
                          </span>
                        </div>
                      </div>
                    )
                  })
                : null}
              {article?.logos?.[0] ? (
                <div className="flex w-full gap-8">
                  <div className="flex items-center text-white">
                    <h4 className="text-12">Published on:</h4>
                  </div>
                  <div className="flex flex-wrap gap-6">
                    {article?.logos.map((logo, index) => {
                      return (
                        <div key={index}>
                          <img src={logo?.image?.localFile?.publicURL} alt="Post" />
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <article className="overflow-hidden">
        <div className="container pt-clamp-45-58 pb-clamp-32-60">
          <div data-aos="fade-up" className="mx-auto lg:w-10/12">
            <div className="grid-cols-10 lg:grid">
              <div className="col-span-10 col-start-2">
                <span className="font-semi text-16">
                  {article.truefalseDate ? null : <time dateTime={date}>{date}</time>}
                </span>
              </div>
            </div>
            <div className={`article-${themeColor}`}>
              {layouts.map((layout, index) => {
                return <PostLayout key={index} layoutData={layout} />
              })}
            </div>
            <div className="grid-cols-10 lg:grid mt-clamp-15-24">
              <div className="col-span-10 col-start-2">
                <Share title={title} slug={slug} />
              </div>
            </div>
            {postLayouts.map((layout, index) => {
              return <PostPostLayout key={index} layoutData={layout} />
            })}
            {article.vimeoBlock.videoId ? (
              <VideoBlock videoId={article.vimeoBlock.videoId} image={article.vimeoBlock.image} />
            ) : null}
            {article.sources !== null && (
              <div className="grid-cols-10 article-sources lg:grid mt-clamp-15-24">
                <div className="col-span-8 col-start-2 border-t pt-clamp-18-25" style={{ borderColor: "#D1CCBD" }}>
                  <h4 className="font-body text-clamp-14-16">Sources:</h4>
                  <ol className="mt-6">
                    {article.sources.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.source} target="_blank" rel="noreferrer" className="break-all">
                            {item.title ? item.title : item.source}
                          </a>
                        </li>
                      )
                    })}
                  </ol>
                </div>
              </div>
            )}
          </div>
        </div>
        {article.relatedArticles !== null && (
          <>
            <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
            <RelatedArticles articles={article.relatedArticles} />
          </>
        )}
      </article>
    </Layout>
  )
}

export default PostTemplate

export const postQuery = graphql`
  query postQuery($id: String!) {
    allWpPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          seo {
            fullHead
          }
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
          article {
            theme
            author {
              ... on WpTeams {
                title
                profile {
                  title
                  profile {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
            vimeoBlock {
              videoId
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
            relatedArticles {
              ... on WpPost {
                title
                slug
                excerpt
                date(formatString: "MMMM DD, YYYY")
                categories {
                  nodes {
                    name
                    slug
                  }
                }
                article {
                  theme
                }
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
            truefalseDate
            sources {
              source
              title
            }
            logos {
              image {
                localFile {
                  publicURL
                }
              }
            }
            articleComponents {
              ... on WpPost_Article_ArticleComponents_TextBlock {
                fieldGroupName
                editor
              }
              ... on WpPost_Article_ArticleComponents_ImageBlock {
                fieldGroupName
                caption
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              ... on WpPost_Article_ArticleComponents_QuoteBlock {
                fieldGroupName
                quote
                from
              }
              ... on WpPost_Article_ArticleComponents_VimeoBlock {
                fieldGroupName
                videoId
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              ... on WpPost_Article_ArticleComponents_ZigZagBlock {
                fieldGroupName
                editor
                flow
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
              ... on WpPost_Article_ArticleComponents_Button {
                fieldGroupName
                buttonLink {
                  title
                  url
                  target
                }
              }
            }
            articlePostComponents {
              ... on WpPost_Article_ArticlePostComponents_ImageBlock {
                caption
                fieldGroupName
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
